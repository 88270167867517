<template>
  <div
    v-click-outside="close"
    class="menu"
    :class="{'menu--open': isOpen }"
  >
    <div class="items">
      <!-- <widget-question-menu-item
        class="item"
        label="Prioritize"
        icon="skip-back-outline"
        @click="close"
      /> -->
      <!-- <widget-question-menu-item
        class="item"
        label="Skip this question"
        icon="undo-outline"
        flip-icon
        @click="close"
      /> -->
      <widget-question-menu-item
        class="item"
        label="Never ask again"
        icon="slash-outline"
        @click="close"
      />
    </div>
    <base-icon
      v-show="isOpen"
      class="button"
      name="close-outline"
      size="26"
      @click="close"
    />

    <base-icon
      v-show="!isOpen"
      class="button"
      name="more-horizontal-outline"
      size="26"
      @click="open"
    />
  </div>
</template>

<script>
import WidgetQuestionMenuItem from '@/components/modules/dashboard/WidgetQuestionMenuItem.vue';

export default {
  components: {
    WidgetQuestionMenuItem,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: absolute;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  overflow: hidden;
  text-align: left;
  background-color: $color-white;
  transition: height 200ms ease;

  .button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px;
    border-radius: 100%;
    cursor: pointer;
    transition: transform 200ms;
    user-select: none;

    &:hover {
      transform: scale(1.2);
    }
  }

  .items {
    padding: 16px;
    opacity: 0;
    transition: opacity 200ms ease;
    pointer-events: none;
  }

  &--open {
    height: 100%;

    .items {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
