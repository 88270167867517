<template>
  <base-widget
    v-if="pulse"
    class="widget-latest-pulse"
  >
    <base-tag
      type="default"
      class="tag"
    >
      {{ pulse.question.category.name }}
    </base-tag>
    <h1 class="title">
      {{ pulse.question.title }}
    </h1>
  </base-widget>
</template>

<script>
export default {
  props: {
    pulse: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userId: this.$root.$data.userId,
    };
  },
};
</script>

<style lang="scss" scoped>
.tag {
  margin-bottom: 16px;
}

.title {
  margin: 0 0 8px;
  font-size: 28px;
  font-family: $font-family-title;
  line-height: 1.2em;
}
</style>
