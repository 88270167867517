<template>
  <div
    class="menu-item"
    @click="$emit('click')"
  >
    <slot>
      <base-icon
        v-if="loading"
        class="icon"
        name="loader-outline"
        animation="spin"
        size="28"
      />
      <base-icon
        v-else-if="icon"
        class="icon"
        :name="icon"
        :flipped="flipIcon"
        size="28"
      />
      <span
        v-if="label"
        class="icon"
      >{{ label }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    flipIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        { 'button--disabled': this.disabled },
        { 'button--loading': this.loading },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  display: flex;
  align-items: center;
  padding: 8px;
  color: $color-text;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 200ms ease;

  &--disabled {
    color: $color-gray70;
    cursor: default;
    pointer-events: none;
  }

  &--loading {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    color: $color-primary;
    background-color: $color-gray10;
  }
}

.icon {
  flex-shrink: 0;
  margin-right: 8px;
}
</style>
