<template>
  <div>
    <base-tag
      class="tag"
      type="primary"
    >
      Getting Started
    </base-tag>
    <h1 class="title">
      Waiting for team members to finish their intake
    </h1>
    <p class="paragraph">
      All members have been asked to answer a few intake questions that helps us determine where we
      should focus on first. So far, <span class="strong">{{ user.active_employees_count }}</span>
      {{ user.added_employees_count === 1 ? 'member' : 'members' }} have activated their account and
      answered the intake question.
    </p>
    <template v-if="user.has_enough_employees">
      <p>
        If you want to get started right away you can, but remember that members have to answer the
        intake question before they will receive pulses.
      </p>
      <base-button
        type="secondary"
        label="Get started"
        :to="{ name: 'setup' }"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
