<template>
  <div>
    <base-tag
      class="tag"
      type="primary"
    >
      Getting Started
    </base-tag>
    <h1 class="title">
      You're all set to start your first question!
    </h1>
    <p class="paragraph">
      Your team members have all activated their accounts. We will send a new question every week.
      You can see which question this is in the planning below.
    </p>
    <base-button
      type="secondary"
      label="Get started"
      :to="{ name: 'setup' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
