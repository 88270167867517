<template>
  <base-widget
    class="averages"
  >
    <template v-if="!avarages">
      <img
        src="~@/assets/images/graphic/results.svg"
        class="empty-graphic"
      >
      <h1 class="title">
        Weekly response & eNPS
      </h1>
      <p class="paragraph">
        When the first responses are coming in, your average results will be shown here.
      </p>
    </template>
  </base-widget>
</template>

<script>

export default {
  props: {
    avarages: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 0 8px;
  font-family: $font-family-title;
  line-height: 1.2em;
}

.empty-graphic {
  display: block;
  width: 100%;
  max-width: 220px;
  margin-bottom: 32px;
}

.paragraph {
  margin: 0;
  color: $color-text-light;
  line-height: 1.5em;
}
</style>
