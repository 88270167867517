<template>
  <base-widget
    class="question"
  >
    <base-tag
      type="default"
      class="tag"
    >
      {{ category }}
    </base-tag>
    <base-title :level="2">
      {{ title }}
    </base-title>
    <widget-question-menu />
  </base-widget>
</template>

<script>
import WidgetQuestionMenu from '@/components/modules/dashboard/WidgetQuestionMenu.vue';

export default {
  components: {
    WidgetQuestionMenu,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  position: relative;
  flex-basis: 0;
}

.tag {
  margin-bottom: 16px;
}
</style>
