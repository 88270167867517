<template>
  <div>
    <base-tag
      class="tag"
      type="primary"
    >
      Getting Started
    </base-tag>
    <h1 class="title">
      Don't forget to send your invitation to your members!
    </h1>
    <p class="paragraph">
      You have added <span class="strong">{{ user.added_employees_count }}</span> new
      {{ user.added_employees_count === 1 ? 'member' : 'members' }} that have not yet been
      invited to participate in Volved. Send the
      {{ user.added_employees_count === 1 ? 'invitation' : 'invitations' }} so we can get
      started.
    </p>
    <base-group spacing="small">
      <base-button
        type="secondary"
        label="Send invites"
        :loading="!!inviteEmployeesLoading"
        @click="sendInvites"
      />
      <base-button
        type="ghost-inverted"
        label="Add More"
        :to="{ name: 'employees' }"
      />
    </base-group>
  </div>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import EMPLOYEES_QUERY from '@/graphql/queries/Employees.gql';

/* Import Mutations */
import INVITE_EMPLOYEE_MUTATION from '@/graphql/mutations/InviteEmployees.gql';

/* Import Utils */
import EventBus from '@/utils/eventBus';

/* Import Classes and Helpers */
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      inviteEmployeesLoading: 0,
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
    },
  },
  methods: {
    async sendInvites() {
      this.inviteEmployeesLoading += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: INVITE_EMPLOYEE_MUTATION,
          variables: { user_id: this.userId },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
            {
              query: EMPLOYEES_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
          ],
        })
        .then(() => {
          // Result
          EventBus.$emit('employees-invited');
          notifyFormSuccess('The invites have been sent out.');
        })
        .catch(() => {
          // Error
          notifyFormError('The invites could not be sent.');
        })
        .finally(() => {
          this.inviteEmployeesLoading -= 1;
        });
    },
  },
};
</script>
