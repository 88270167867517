<template>
  <div class="countdown">
    <div class="number number--days">
      <div class="main">
        {{ String(daysRemaining).padStart(2, '0') }}
      </div>
      <div class="sub">
        days
      </div>
    </div>
    <div class="separator separator--wide" />
    <div class="number number--hours number--time">
      <div class="main">
        {{ String(hoursRemaining).padStart(2, '0') }}
      </div>
      <div class="sub">
        hours
      </div>
    </div>
    <div class="separator">
      :
    </div>
    <div class="number number--minutes number--time">
      <div class="main">
        {{ String(minutesRemaining).padStart(2, '0') }}
      </div>
      <div class="sub">
        minutes
      </div>
    </div>
    <div class="separator">
      :
    </div>
    <div class="number number--seconds number--time">
      <div class="main">
        {{ String(secondsRemaining).padStart(2, '0') }}
      </div>
      <div class="sub">
        seconds
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timer: null,
      totalSecondsRemaining: 0,
    };
  },
  computed: {
    daysRemaining() {
      return Math.floor((this.totalSecondsRemaining % (60 * 60 * 24 * 365)) / (60 * 60 * 24));
    },
    hoursRemaining() {
      return Math.floor((this.totalSecondsRemaining % (60 * 60 * 24)) / (60 * 60));
    },
    minutesRemaining() {
      return Math.floor((this.totalSecondsRemaining % (60 * 60)) / (60));
    },
    secondsRemaining() {
      return Math.floor((this.totalSecondsRemaining % 60));
    },
  },
  watch: {
    seconds: {
      handler(seconds) {
        this.totalSecondsRemaining = seconds;
      },
      immediate: true,
    },
  },
  mounted() {
    this.timer = window.setInterval(() => {
      this.totalSecondsRemaining -= 1;
      if (this.totalSecondsRemaining === 0) {
        clearInterval(this.timer);
        this.$emit('finish');
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  display: flex;
  text-align: center;
}

.number {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  font-size: 64px;
}

.main {
  height: 64px;
  font-weight: black;
  font-family: $font-family-title;
}

.sub {
  color: $color-primary10;
  font-size: 14px;
}

.separator {
  min-width: 8px;
  padding-top: 16px;
  font-weight: black;
  font-size: 32px;
  font-family: $font-family-title;

  &--wide {
    min-width: 16px;
  }
}
</style>
