<template>
  <base-page
    class="dashboard"
    title="Welcome"
  >
    <div class="main">
      <base-widget-group
        class="column column--pulse"
        :title="pulse ? 'Latest Question' : 'Welcome'"
      >
        <widget-latest-pulse
          v-if="pulse"
          :pulse="pulse"
        />
        <widget-getting-started v-else />
      </base-widget-group>
      <base-widget-group
        class="column column--averages"
        title="Averages"
      >
        <widget-averages />
      </base-widget-group>
    </div>

    <base-widget-group title="Upcoming">
      <div class="upcoming">
        <widget-question
          v-for="question in upcomingQuestions"
          :key="question.id"
          :title="question.title"
          :category="question.category.name"
          class="column column--scheduled"
        />
      </div>
    </base-widget-group>
  </base-page>
</template>

<script>
/* Import Queries */
import PULSES_QUERY from '@/graphql/queries/Pulses.gql';
import UPCOMING_QUESTIONS_QUERY from '@/graphql/queries/UpcomingQuestions.gql';

/* Import Components */
import WidgetGettingStarted from '@/components/modules/dashboard/WidgetGettingStarted.vue';
import WidgetLatestPulse from '@/components/modules/dashboard/WidgetLatestPulse.vue';
import WidgetAverages from '@/components/modules/dashboard/WidgetAverages.vue';
import WidgetQuestion from '@/components/modules/dashboard/WidgetQuestion.vue';

export default {
  components: {
    WidgetGettingStarted,
    WidgetLatestPulse,
    WidgetAverages,
    WidgetQuestion,
  },
  data() {
    return {
      userId: this.$root.$data.userId,
    };
  },
  computed: {
    pulse() {
      return this.pulses ? this.pulses[0] : null;
    },
  },
  apollo: {
    pulses: {
      query: PULSES_QUERY,
      variables() {
        return {
          user_id: this.userId,
          active: true,
        };
      },
    },
    upcomingQuestions: {
      query: UPCOMING_QUESTIONS_QUERY,
      variables() {
        return {
          user_id: this.userId,
          limit: 4,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  margin-right: -16px;
}

.upcoming {
  display: flex;
  margin-right: -16px;
}

.column {
  margin-right: 16px;

  &--pulse {
    width: 60%;
  }

  &--averages {
    width: 40%;
  }
}
</style>
