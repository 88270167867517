<template>
  <div>
    <base-tag
      class="tag"
      type="primary"
    >
      Getting Started
    </base-tag>
    <h1 class="title">
      Start creating teams and inviting team members.
    </h1>
    <p class="paragraph">
      To display a clear overview of results while maintaining privacy,
      you can use teams of at least {{ user.minimum_employees }} people.
    </p>
    <base-button
      type="secondary"
      label="Add Team Members"
      :to="{ name: 'employees' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
