<template>
  <div>
    <base-tag
      class="tag"
      type="primary"
    >
      Getting Started
    </base-tag>
    <h1 class="title">
      The party is about to start!
    </h1>
    <widget-getting-started-countdown
      :seconds="user.time_until_next_question"
      class="countdown"
    />
    <p class="paragraph">
      In the meantime, to get to know your company better your team has the chance to answer
      some intake questions.
    </p>
  </div>
</template>

<script>
/* Import Components */
import WidgetGettingStartedCountdown from './WidgetGettingStartedCountdown.vue';

export default {
  components: {
    WidgetGettingStartedCountdown,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  margin-bottom: 16px;
}
</style>
