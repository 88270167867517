<template>
  <base-widget
    type="dark"
    class="widget-getting-started"
  >
    <transition-fade>
      <widget-getting-started-loading
        v-if="$apollo.queries.user.loading"
        :key="0"
      />
      <template v-else-if="!user.setup_completed">
        <widget-getting-started-step1
          v-if="waitingForAdding"
          :key="1"
          :user="user"
        />
        <widget-getting-started-step2
          v-else-if="waitingForInviting"
          :key="2"
          :user="user"
        />
        <widget-getting-started-step3
          v-else-if="waitingForActivating"
          :key="3"
          :user="user"
        />
        <widget-getting-started-step4
          v-else
          :key="4"
          :user="user"
        />
      </template>
      <widget-getting-started-step5
        v-else
        :key="5"
        :user="user"
      />
    </transition-fade>
  </base-widget>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';

/* Import Components */
import WidgetGettingStartedLoading from './WidgetGettingStartedLoading.vue';
import WidgetGettingStartedStep1 from './WidgetGettingStartedStep1.vue';
import WidgetGettingStartedStep2 from './WidgetGettingStartedStep2.vue';
import WidgetGettingStartedStep3 from './WidgetGettingStartedStep3.vue';
import WidgetGettingStartedStep4 from './WidgetGettingStartedStep4.vue';
import WidgetGettingStartedStep5 from './WidgetGettingStartedStep5.vue';

export default {
  components: {
    WidgetGettingStartedLoading,
    WidgetGettingStartedStep1,
    WidgetGettingStartedStep2,
    WidgetGettingStartedStep3,
    WidgetGettingStartedStep4,
    WidgetGettingStartedStep5,
  },
  data() {
    return {
      userId: this.$root.$data.userId,
    };
  },
  computed: {
    /**
     * Returns true if the total employee count is lower than the required amount
     */
    waitingForAdding() {
      return this.user.employees_count < this.user.minimum_employees;
    },
    /**
     * Returns true if the amount of employees without status 'added' is lower than the required
     * amount
     */
    waitingForInviting() {
      return this.user.employees_count - this.user.added_employees_count
        < this.user.minimum_employees;
    },
    /**
     * Returns true if there are no employees with status 'invited' or 'added'
     */
    waitingForActivating() {
      return this.user.added_employees_count + this.user.invited_employees_count > 0;
    },
    /**
     * Returns true if the setup has been completed
     */
    setupCompleted() {
      return this.user ? this.user.setup_completed : false;
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
    },
  },
  watch: {
    setupCompleted: {
      handler(completed) {
        if (!this.$apollo.queries.user) return;
        if (completed) {
          // Auto poll for counter to always be up-to-date
          this.$apollo.queries.user.startPolling(60000);
        } else {
          this.$apollo.queries.user.stopPolling();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-getting-started {
  display: flex;
  flex-direction: column;
  box-shadow: 0 12px 32px rgba($color-primary90, 0.4) !important;

  ::v-deep .tag {
    margin-bottom: 24px;
  }

  ::v-deep .title {
    margin: 0 0 16px;
    font-size: 32px;
    font-family: $font-family-title;
    line-height: 1.15em;
  }

  ::v-deep .paragraph {
    margin: 0 0 32px;
    color: $color-primary5;
    line-height: 1.5em;

    .strong {
      color: $color-white;
    }
  }
}
</style>
